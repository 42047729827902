import Vue from "vue";
import Vuex from "vuex";

import { callInfo } from './modules/callInfo'
import { dialogCont } from './modules/dialogCont'
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    authorities: [],
    userInfo: {
      photo: '',
      name: "",
      username: '',
      mobile: '',
      id: '',
      roleIdList: [],
      orgId: "",
      orgName: "",
    },
    fixNavmenuActiveKey: null,
    navIsOpen: true,
  },
  mutations: {
    updateAuthorities(state, authorities){
      state.authorities = authorities
    },
    updateUserInfo(state, userInfo) {
      state.userInfo = { ...state.userInfo, ...userInfo}
    },
    updateFixNavmenuActiveKey(state, fixNavmenuActiveKey) {
      state.fixNavmenuActiveKey = fixNavmenuActiveKey
    },
    updateNavIsOpen(state, navIsOpen){
      state.navIsOpen = navIsOpen
    },
  },
  actions: {},
  modules: {
    callInfo,
    dialogCont,
  },
});
