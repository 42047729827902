import Vue from 'vue'
import VideoPreview from './videoPreview.vue'

const MyVideoPreview = Vue.extend(VideoPreview)

VideoPreview.install = (Vue) => {
  return Vue.prototype.$VideoPreview = (options) => {
    let { url } = options
    let instance = new MyVideoPreview({
      data: {
        url,
      },
    }).$mount()
  
    document.body.appendChild(instance.$el)

    return instance.init()
  }
}

export default VideoPreview