import Vue from 'vue'
import FilePreview from './filePreview.vue'
import { pdfServeUrl, officeServeUrl } from '@/config'
const MyFilePreview = Vue.extend(FilePreview)

FilePreview.install = (Vue) => {
  return Vue.prototype.$FilePreview = (options) => {
    let { url } = options
    if(/\.pdf$/gi.test(url)){
        url = `${pdfServeUrl}?file=${url}`
    }else if(/\.(xls|xlsx|doc|docx|ppt|pptx)$/gi.test(url)){
        url = `${officeServeUrl}?src=${url}`
    }else{
        throw new Error('格式只能是pdf,ppt,pptx,xls,xlsx,doc,docx类型！')
    }
    console.log("url: ", url)
    let instance = new MyFilePreview({
      data: {
        url,
      },
    }).$mount()
  
    document.body.appendChild(instance.$el)

    return instance.init()
  }
}

export default FilePreview