<template>
  <div class="home">
    <div class="left">
      <left-nav></left-nav>
    </div>
    <div class="right">
      <top-header></top-header>
      <div class="main" :style="{'min-width': isMobile ? '960px' : '1160px'}">
        <div class="page-area">
          <transition name="fade-transform" mode="out-in">
            <!-- <keep-alive> -->
            <router-view :key="$route.fullPath" />
            <!-- </keep-alive> -->
          </transition>
        </div>
      </div>
    </div>
    <div class="call-short" v-if="saleType">
      <img
        class="call-img"
        :src="require('@/assets/image/home/call-icon-1.png')"
        alt=""
        title="点击显示电话浮框"
        @click="handleCallIconClick"
      />
    </div>
    <staffReceiveCallDialog v-if="saleType == 1" />
    <parttimeReceiveCallDialog v-else-if="saleType == 2" />
  </div>
</template>

<script>
import topHeader from "@/components/layout/topHeader";
import leftNav from "@/components/layout/leftNav";
import { mapMutations, mapState } from "vuex";
const dayjs = require("dayjs");
const duration = require("dayjs/plugin/duration");
dayjs.extend(duration);
import { getTimeTotal, dealHandCallUserfield, isMobile, apiGetYyyNumInfo } from "@/utils/tool";
import parttimeReceiveCallDialog from "@/components/sales/parttimeReceiveCallDialog";
import staffReceiveCallDialog from "@/components/sales/staffReceiveCallDialog";
import {
  apiSaveSaleCallRecord,
  apiCallSysAddBlack,
  apiGetUserSeasNum,
  apiGetCallAccountShowNum,
  apiSetCallAccountShowNum,
} from "@/api/sales";
import { socketUrl, pathName, envType } from "@/config/index";
import { apiApiDataSendServe } from "@/api/index";
const defaultLastHandBusyType = 1;
const { io } = require("socket.io-client");

export default {
  data() {
    return {
      isMobile: isMobile(),
      isSalesImporter: false,
      saleType: null, //saleType  1 销售专员 2 销售兼职
      dkyAgentType: 1, // 1 启用 2 禁用
      newAddUseNum: 0,
      newHighSeasNum: sessionStorage.getItem("highSeasNum"),
      callInfo: {
        isUseDiffExplicitNumber: false,  // 是否启用不同的外显
        explicitNumber1: "",  // 新数据专用外显
        explicitNumber2: "",  // 真实数据专用外显
        explicitNumber0: null, // 当前外显
        transferType: 1, // 1 盲转 2 非盲转
        isAllocCallAccount: true,
        phoneId: process.env.VUE_APP_PHONEID, //"707e25222228be4fd8caa2a4d0379bf1",
        //phoneId: "de274cf81743710a4e44aa23ab9929b7",
        yyyAgentAccount: "1068@yyykfkh",
        yyyAgentNum: "1068",
        yyyAgentPassword: "asdf12345",
        saleType: "",
        queue: "", // 10037001001153  70000001128935
        //queue: "70000001128937",
        msg: "",
        callStatus: 0, // 0 默认状态 1 呼叫中 2 响铃 3 通话中 4 通话已结束 5 来电响铃 6 来电响铃-点击接听  7 刚点击手动拨号  (来电处理)
        handCallStatus: 0, // 0 默认状态 1 呼叫中 2 响铃 3 通话中 4 通话已结束 5 来电响铃 6 来电响铃-点击接听  7 刚点击手动拨号  8 坐席挂断  9 用户挂断  10 用户未接 11 已挂断（可能是号码拨打完情况）  （手动拨打处理）
        changeCallStatus: 0, // 主动转接参数 0 默认状态  1 转接中 2 转接成功 3 转接失败 4 结束 5 对方接听
        blindferCallStatus: 0, // 盲转参数 0 默认状态 1 转接中 2 转接成功 3 转接失败
        isCallLogin: false,
        loginStatus: 0, // 登录状态 0 默认 1 登录成功 2 登录失败 3 退出登录
        isPause: false,
        isMute: false,
        isBusy: false, // 真实置忙置闲
        _lastHandBusyType: defaultLastHandBusyType, // 手动点击置忙置闲状态(及对应开启和暂停按钮)
        _lastAutoBusyType: 0, // 程序根据是否填写客户类别自动置忙置闲
        _isHandHangUp: false, // 手动拨打时，是否是自己点的挂断
        callType: 1, // 0 默认 1 预测外呼分配 2 转接 3 手动拨打 4 客户来电
        handCallType: 1, // 1 新数据列表 2 真实数据列表
        isFromHandCall: false, // 是否来自手拨列表中的拨打电话
        linkedid: "", // 共用linkedid
        handCallLinkedid: "", // 手动拨打linkedid
        autoCallLinkedid: "", // 兼职预测外呼linkedid
        transforLinkedid: "", // 兼职转接给专员后专员linkedid
        duration: "",
        handDuration: "",
        studentId: "",
        ext2: "", // 转接销售分机号
        _handCallStudentId: null,
        isHandCallBlack: false,
        _continueOnTime: 0,
        continueOnTime: "00:00:00",
        _continueOffTime: 0,
        continueOffTime: "00:00:00",
        incomingNum: "",
        isClickHandup: false,
        useCallPlatform: 0,
      },
      sysCallInfo: {
        flagOn: false, // 真实系统设置当前能不能拨打电话
        causeTxt: null, // 原因
      },
      socket: null,
    };
  },
  computed: {
    ...mapState(["navIsOpen"]),
    ...mapState("dialogCont", ["dialogCallInfo"]),
    ...mapState("callInfo", ["sysStatus"]),
  },
  watch: {
    "$router.path"() {
      console.log("this.$router.path: ", this.$router.path)
    },
    "callInfo.isCallLogin"(isCallLogin) {
      console.log("isCallLogin: ", isCallLogin);
      if (isCallLogin) {
        //this.handleContinueTimeCompute();
      }
    },
    sysStatus: {
      handler() {
        if (!this.saleType) {
          return;
        }
        console.log("sysStatus: ", JSON.parse(JSON.stringify(this.sysStatus)));
        clearTimeout(this._sysCallTimer);
        this._sysCallTimer = setTimeout(() => {
          console.log("拨打功能校验");
          this.handleSysCallStatus();
        }, 1000);
      },
      deep: true,
    },
    "sysCallInfo.flagOn"(flagOn) {
      if (this.saleType && this.callInfo.isAllocCallAccount && this.dkyAgentType != 2) {
        if (flagOn) {
          if (envType == "local") {
            // 电话功能
            this.initData();
          } else {
            this.initData();
          }
        } else {
          this.msgWarn(this.sysCallInfo.causeTxt);
          clearTimeout(this._sysCallLogoutTimer);
          this._sysCallLogoutTimer = setTimeout(() => {
            this.handleTryLogout();
          }, 500);
        }
      }
    },
  },
  components: {
    topHeader,
    leftNav,
    parttimeReceiveCallDialog,
    staffReceiveCallDialog,
  },
  provide() {
    return {
      main: this,
    };
  },
  beforeCreate() {},
  created() {
    console.log("home created");
    //sessionStorage.setItem("yyyAgentAccount", this.callInfo.yyyAgentAccount);
    //sessionStorage.setItem("yyyAgentPassword", this.callInfo.yyyAgentPassword);
    this.dkyAgentType = sessionStorage.getItem("dkyAgentType");
    this.callInfo.yyyAgentAccount = sessionStorage.getItem("yyyAgentAccount");
    if (this.callInfo.yyyAgentAccount) {
      this.callInfo.yyyAgentNum = this.callInfo.yyyAgentAccount.split("@")[0];
    } else {
      this.callInfo.yyyAgentNum = null;
    }
    this.callInfo.yyyAgentPassword = sessionStorage.getItem("yyyAgentPassword");
    this.callInfo.isAllocCallAccount = !!(
      this.callInfo.yyyAgentAccount && this.callInfo.yyyAgentPassword
    );
    const queueNumber = sessionStorage.getItem("queueNumber")
      ? sessionStorage.getItem("queueNumber")
      : null;
    this.callInfo.useCallPlatform = sessionStorage.getItem("useCallPlatform")
    let saleType = sessionStorage.getItem("saleType");
    if (saleType == 1) {
      this.callInfo.queue = null;
      if (sessionStorage.getItem("useCallPlatform") == 0) {
        this.showConfirmInfo("当前用户没有分配电话服务，不能拨打电话！");
        return;
      }
      if (!this.callInfo.isAllocCallAccount) {
        this.showConfirmInfo("该销售角色账号，没有分配坐席，不能用电话功能！");
      }
    } else if (saleType == 2) {
      this.callInfo.queue = queueNumber;
      if (sessionStorage.getItem("useCallPlatform") == 0) {
        this.showConfirmInfo("当前用户没有分配电话服务，不能拨打电话！");
        return;
      }
      if (!this.callInfo.isAllocCallAccount) {
        this.showConfirmInfo("该销售角色账号，没有分配坐席，不能用电话功能！");
      } else if (!queueNumber) {
        /* this.showConfirmInfo(
          "该兼职角色账号没有配置任务队列，将不会收到预测外呼电话，只能用于手动拨打！"
        ); */
      }
    }
    // 暂时设定为1,测试专员
    this.saleType = saleType;
    this.isSalesImporter = !!sessionStorage.getItem("salesImporter");
    if (this.saleType || this.isSalesImporter) {
      this.initSocket();
    }
    this.sysCallInfo.flagOn = false;
    this.sysCallInfo.causeTxt = null;
    this.sysStatusChange({
      status: 1, // 1 开启 2 关闭
      startTime: null,
      endTime: null,
      dataFlag: false, // 数据已获取
    });
    this.handleStartCheckSysCallStatus();
  },
  mounted() {
    window.addEventListener("message", this.callCallbackEvent);
    console.log("$router: ", this.$router)
  },
  beforeDestroy() {
    this.handleUserLogout();
    this.handleLogoutSocket();
    window.removeEventListener("message", this.callCallbackEvent);
  },
  beforeRouteUpdate(to, from, next) {
    next();
  },
  methods: {
    ...mapMutations(["updateAuthorities"]),
    ...mapMutations("dialogCont", ["dialogCallInfoChange"]),
    ...mapMutations("callInfo", ["sysStatusChange"]),
    /* 切换外显号码后重新登录 */
    handleReLogin() {
      if (!this.callInfo.isCallLogin) {
        return;
      }
      this.handleUserLogout();
      let _lastHandBusyType = this.callInfo._lastHandBusyType
      let type = "ready";
      this.callInfo._lastHandBusyType = 0;
      if (_lastHandBusyType == 1 || _lastHandBusyType == null) {
        type = "busy";
        this.callInfo._lastHandBusyType = 1;
      }
      let obj = {
        key: "login", // 操作标识
        data: {
          id: this.callInfo.phoneId, // 对接ID
          agent: this.callInfo.yyyAgentAccount, // 登录账号，如'8004@1030test'
          password: this.callInfo.yyyAgentPassword, // 密码
          mode: "8", // 登录方式，（’1’：手机登录 ，’2’：话机接听，’4’：插件登录，’6’：WebRTC，’7’：SIM卡话机接听，’8’：SIP软电话接听）
          type, // 座席状态，ready:空闲，busy:忙碌，以api文档为准
        },
      };
      console.log("obj: ", obj);
      telephoneFunction(obj); // 调用对外函数
    },
    /* 获取坐席外显号码 */
    async getCallAccountShowNum() {
      try {
        const { type, num } = await apiGetYyyNumInfo()
        this.callInfo.explicitNumber2 = num
        this.callInfo.isUseDiffExplicitNumber = type == 1
        const { data, errmsg, errcode } = await apiGetCallAccountShowNum({
          agent_num: this.callInfo.yyyAgentNum,
        });
        if (errcode == 0) {
          data.forEach((item) => {
            if (item.isChoose) {
              this.callInfo.explicitNumber0 = item.explicitNumber;
            } else {
              this.callInfo.explicitNumber1 = item.explicitNumber
            }
          });
        } else {
          this.msgError("获取外显号码发生异常！");
        }
      } catch (err) {
        this.msgError("获取外显号码发生异常！");
        console.error("apiGetCallAccountShowNum err: ", err);
      }
    },
    /* 拨打新数据和真实数据外显不匹配时提示 */
    handleShowNumTip(type) {
      let memo;
      if (type == 1) {
        memo = this.callInfo.explicitNumber1;
      } else if (type == 2) {
        memo = this.callInfo.explicitNumber2;
      }
      this.$confirm(
        type == 1
          ? "当前线路为“真实数据”线路，需要切换到“新数据”线路，才能拨打新数据?"
          : "当前线路为“新数据”线路，需要切换到“真实数据”线路，才能拨打真实数据?",
        "提示",
        {
          confirmButtonText: "切换",
          cancelButtonText: "不切换",
          closeOnClickModal: false,
          type: "warning",
        }
      )
        .then(async () => {
          try {
            const resData = await apiSetCallAccountShowNum({
              agent_num: this.callInfo.yyyAgentNum,
              memo,
            });
            const data = JSON.parse(resData);
            if (data.errcode == 0) {
              this.msgSuccess("线路切换成功，电话重新登录中！");
              this.handleReLogin();
            } else {
              this.msgError("线路切换失败！");
            }
          } catch (err) {
            console.error("apiSetCallAccountShowNum err: ", err);
            this.msgError("切换线路发生异常！");
          }
        })
        .catch(() => {
          
        });
    },
    callCallbackEvent(e) {
      //console.log("callCallbackEvent", e)
      if (e.data.type == "webpackOk") {
        return;
      }
      let json = JSON.parse(e.data);
      console.log("%c%s", "color: white; background: blue", "---listener message:", json);
      this.handleSaveCallData(json);
      switch (json.key) {
        case "MobilesCityApi":
          break;
        case "GetTagDataApi":
          break;
        case "GetTransferApi":
          break;
        case "TransferSuccess":
          break;
        case "GetIvrApi":
          break;
        case "GetlistSatisfactionNodesApi":
          break;
        case "AccountIsLogouted":
          this.msgWarn(json.name);
          this.callInfo.isCallLogin = false;
          this.callInfo.loginStatus = 3;
          setTimeout(() => {
            this.$router.push({
              path: "/login",
            });
            location.reload();
          }, 2000);
          break;
        case "CTIEvent":
          switch (json.data.command) {
            case "agentstatus": // 坐席状态改变
              if (json.data.stateto == "ready") {
                this.callInfo.isBusy = false;
              } else if (json.data.stateto == "busy") {
                this.callInfo.isBusy = true;
              }
              break;
            case "callout_process": // 呼叫中事件
              this.callInfo.linkedid = json.data.sessionid;
              // 重置转接状态
              this.callInfo.changeCallStatus = 0;
              this.callInfo.blindferCallStatus = 0;
              if (json.data.calldirection == "call out") {
                this.callInfo.handCallStatus = 1;
              }
              break;
            case "call_process": // 被叫响铃事件
              this.callInfo.linkedid = json.data.sessionid;
              if (json.data.calldirection == "call out") {
                this.callInfo.handCallStatus = 2;
                this.handleStartTimeTimer();
              }
              break;
            case "consult_process": // 咨询响铃事件
              break;
            case "call_talking": // 被叫接听事件
              this.callInfo.linkedid = json.data.sessionid;
              if (json.data.calldirection == "call out") {
                if (this.callInfo.callType == 2) {
                  this.callInfo.callStatus = 3;
                } else {
                  this.callInfo.handCallStatus = 3;
                }
              } else if (json.data.calldirection == "call in") {
                this.callInfo.callStatus = 3;
              }
              this.handleStartTimeTimer();
              break;
            case "consult_talking": // 咨询接通
              // 咨询接通后，咨询可以互相通话
              break;
            case "call_hangup": // 通话结束事件
              if (json.data.calldirection == "call out") {
                if (this.callInfo.callType == 2 || this.callInfo.callType == 4) {
                  if (this._bindferEndTimer) {
                    clearInterval(this._bindferEndTimer);
                    this._bindferEndTimer = null;
                    this.callInfo.blindferCallStatus = 0;
                  }
                  this.callInfo.callStatus = 4;
                } else {
                  if (this.callInfo.handCallStatus == 3) {
                    this.callInfo.handCallStatus = 4;
                  } else {
                    this.callInfo.handCallStatus = 11;
                  }
                }
              } else if (json.data.calldirection == "call in") {
                this.callInfo.callStatus = 4;
              }
              this.handleStopTimeTimer();
              break;
            case "consult_hangup": // 咨询挂机
              // 如果a咨询b,b接通后，a直接挂断，也会触发这个事件，并且这个事件在 call_hangup 之前
              break;
            case "call_consultagent": // 咨询失败
              //a咨询b,b挂断
              if (json.data.result == "dnisCallfailed") {
              }
              break;
            case "incoming": // 客户来电事件
              this.callInfo.linkedid = json.data.sessionid;
              this.callInfo.incomingNum = json.data.ani;
              // 重置转接状态
              this.callInfo.changeCallStatus = 0;
              this.callInfo.blindferCallStatus = 0;
              if (json.data.calldirection == "call in") {
                this.callInfo.callType = 4;
                this.callInfo.callStatus = 5;
              }
              break;
            case "consult_incoming": // 咨询来电
              this.callInfo.linkedid = json.data.sessionid;
              break;
            case "transfer_incoming": // 转接来电
              this.callInfo.linkedid = json.data.sessionid;
              this.callInfo.incomingNum = json.data.ani;
              if (json.data.calldirection == "call out") {
                this.callInfo.callType = 2;
                this.callInfo.callStatus = 5;
              }
              break;
            case "call_transferagent": // 转接拒绝
              if (json.data.result == "dnisCallfailed") {
                this.msgWarn("转接失败，通话结束！");
                this.callInfo.blindferCallStatus = 3;
                if (this._bindferEndTimer) {
                  clearInterval(this._bindferEndTimer);
                  this._bindferEndTimer = null;
                }
                this.handleDKYHangupAll();
              }
            case "conference_start": // 三方通话开始
              break;
            case "conference_aniHangup": // 三方通话挂断
              break;
            case "conference_end": // 三方通话结束
              break;
          }
          break;
        case "CTIResponse":
          switch (json.data.command) {
            case "agentLogin": //  登录事件
              if (json.data.retcode == 200) {
                this.callInfo.isCallLogin = true;
                this.callInfo.loginStatus = 1;
                console.log("电话登录成功");
                this.msgSuccess("电话登录成功！")
                this.getCallAccountShowNum();
              } else {
                this.callInfo.isCallLogin = false;
                this.callInfo.loginStatus = 2;
                this.msgError("电话登录失败！");
                console.log("电话登录失败");
              }
              break;
            case "agentLogout": // 登出事件
              if (json.data.retcode == 200) {
                console.log("电话登出成功");
                this.callInfo.isCallLogin = false;
                this.callInfo.loginStatus = 3;
              } else {
                console.log("电话登出失败");
              }
              break;
            case "agentSetState": // 坐席状态改变
              if (json.data.retcode == 200) {
                console.log("电话坐席状态切换成功！");
              } else {
                console.log("电话坐席状态切换失败！");
              }
              break;
            case "agentDialout": // 外呼事件
              this.callInfo.linkedid = json.data.sessionid;
              if (json.data.retcode != 200) {
                this.handleStopTimeTimer();
                console.log("电话外呼失败");
                this.msgError(json.data.retmsg);
                this.callInfo.handCallStatus = 0;
                return;
              }
              console.log("电话外呼中");
              this.callInfo.changeCallStatus = 0;
              this.callInfo.callType = 3;
              this.callInfo.handCallStatus = 1;
              // 前端拨打保存记录
              this.handleSaveCallRecord();
              break;
            case "agentHangup": // 坐席挂断
              if (json.data.retcode == 200) {
                console.log("电话挂断成功");
              } else {
                console.log("电话挂断失败");
              }
              break;
            case "agentTransfer": // 转接事件 (也可能是咨询转移坐席)
              if (json.data.retcode == 200) {
                console.log("电话转接中");
                this.msgSuccess("被转接方已响铃！待接听");
                //this.callInfo.blindferCallStatus = 2
                //this.handleDKYHangupAll()
              } else {
                this.callInfo.blindferCallStatus = 3;
                console.log("电话转接失败");
                this.msgError("转接失败！");
              }
              break;
            case "agentcancelConsult": // 取消咨询
              break;
            case "agentThreeWayCall": // 三方通话
              break;
          }
          break;
        case "CTIMessage":
          break;
        case "PluginConnectState":
          break;
        case "ModeChangeState":
          break;
        case "AssociateClicked":
          break;
        case "transferSuccess":
          if (this.callInfo.blindferCallStatus != 3 && !this.callInfo.isClickHandup) {
            this.msgSuccess("转接成功！通话结束");
          }
          if (this._bindferEndTimer) {
            clearInterval(this._bindferEndTimer);
            this._bindferEndTimer = null;
            this.callInfo.blindferCallStatus == 2;
          }
          break;
      }
    },
    setCurUseCallPlatform(curUseCallPlatform) {
      if (curUseCallPlatform != sessionStorage.getItem("useCallPlatform")) {
        apiApiDataSendServe({
          type: "call-agent-change",
          userId: sessionStorage.getItem("userId"),
          oldUseCallPlatform: sessionStorage.getItem("useCallPlatform"),
          newUseCallPlatform: curUseCallPlatform,
        })
        this.$confirm("坐席状态发生了改变，请退出重新登录?", "提示", {
          closeOnClickModal: false,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push({
              path: "/login",
            });
            setTimeout(() => {
              location.reload();
            }, 1000);
          })
          .catch(() => {});
      }
    },
    setCurDkyAgentAccount(yyyAgentAccount) {
      if (
        (this.callInfo.yyyAgentAccount || yyyAgentAccount) &&
        this.callInfo.yyyAgentAccount != yyyAgentAccount
      ) {
        apiApiDataSendServe({
          type: "call-agent-change",
          userId: sessionStorage.getItem("userId"),
          oldYyyAgentAccount: this.callInfo.yyyAgentAccount,
          newYyyAgentAccount: yyyAgentAccount,
        })
        this.$confirm("坐席状态发生了改变，请退出重新登录?", "提示", {
          closeOnClickModal: false,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push({
              path: "/login",
            });
            setTimeout(() => {
              location.reload();
            }, 1000);
          })
          .catch(() => {});
      }
    },
    /* 获取公海新数据数量 */
    async getNewHighSeasNum() {
      try {
        const resData = await apiGetUserSeasNum({
          userId: sessionStorage.getItem("userId"),
        });
        this.newHighSeasNum = resData;
      } catch (err) {
        let msg = err.msg ? err.msg : "获取公海新数据数量发生异常！";
        this.msgError(msg);
        console.error("apiGetUserSeasNum err: ", err);
      }
    },
    /* 测试代码 */
    handleTest() {
      if (process.env.VUE_APP_SERVER_ENV == "dev") {
        this.callInfo.studentId = 23273;
        this.callInfo.callStatus = 5;
        this.callInfo.callType = 2;
        this.callInfo.linkedid = null;
      }
    },
    /* 保存电话数据 */
    handleSaveCallData(data) {
      /* if (this.socket && envType != "env") {
        this.socket.emit("callDataChange", data);
      } */
      let studentId = this.callInfo._handCallStudentId
        ? this.callInfo._handCallStudentId
        : this.callInfo.studentId;
      this.socket.emit("callDataChange", { ...data, studentId, callPlatform: 2 });
    },
    /* 添加运营商电话黑名单 */
    async handleCallSysAddBlack(studentId) {
      try {
        await apiCallSysAddBlack({
          studentId: studentId ? studentId : this.callInfo._handCallStudentId,
        });
        this.msgSuccess("该手机号已添加到系统黑名单！请拨打下一个吧！");
      } catch (err) {
        let msg = err.msg ? err.msg : "添加黑名单发生异常！";
        this.msgError(msg);
        console.error("apiCallSysAddBlack err: ", err);
      }
    },
    /* 保存手拨通话记录 */
    async handleSaveCallRecord() {
      try {
        if (!this.callInfo.isFromHandCall) {
          return;
        }
        const agent = sessionStorage.getItem("yyyAgentAccount");
        const saveData = {
          studentId: this.callInfo._handCallStudentId,
          linkedid: this.callInfo.linkedid,
          userId: sessionStorage.getItem("userId"),
          type: this.callInfo.handCallType,
          agent,
        };
        await apiSaveSaleCallRecord(saveData);
      } catch (err) {
        let msg = err.msg ? err.msg : "保存通话记录发生异常！";
        this.msgError(msg);
        console.error("apiSaveSaleCallRecord err: ", err);
      }
    },
    /* 保存预测外呼通话记录 */
    async handleSaveAutoCallRecord(linkedid, type) {
      try {
        const agent = sessionStorage.getItem("yyyAgentAccount");
        const saveData = {
          studentId: this.callInfo.studentId,
          linkedid,
          userId: sessionStorage.getItem("userId"),
          type,
          agent,
        };
        await apiSaveSaleCallRecord(saveData);
      } catch (err) {
        let msg = err.msg ? err.msg : "保存通话记录发生异常！";
        this.msgError(msg);
        console.error("apiSaveSaleCallRecord err: ", err);
      }
    },
    /* 初始化socket */
    initSocket() {
      //const url = 'http://new.1.test.manage.bjsihao.cn/'
      //const url = "http://101.200.212.33:9092"
      //const url = "http://localhost:3005/";
      //const url = "http://localhost:3008"
      //const url = "http://dev.socket.liusandao.top"
      //const url = "http://39.98.157.218:3008"
      const token = sessionStorage.getItem("Authorization");
      const userId = sessionStorage.getItem("userId");
      if (!token || token == "null") {
        return;
      }
      const socket = io(socketUrl, {
        path: `/${pathName}`,
        cors_allowed_origins: [],
        query: {
          token,
          userId,
        },
      });
      this.socket = socket;
      socket.on("connect", () => {
        console.log(`my connect: ${socket.connected}`); // true
      });

      socket.on("disconnect", () => {
        console.log(`my disconnect: ${socket.connected}`); // false
      });

      socket.on("data", (e) => {
        console.log("data: ", e);
      });

      socket.on("connect_error", (e) => {
        console.log("my connect_error： ", e);
        //socket.connect();
        if (e.message == "Authentication failed.") {
          this.msgError("socket权限校验失败！");
        } else {
          // this.msgError("socket链接失败！");
        }
      });
      socket.on("parttimeAddUse", (e) => {
        console.log("parttimeAddUse: ", e);
        this.newAddUseNum = e.total;
      });
      socket.on("sysCallStatusUpdateToClient", (e) => {
        console.log("sysCallStatusUpdateToClient: ", e);
        this.sysStatusChange(e);
      });
      socket.on("sysCallStartTipToClient", (e) => {
        console.log("sysCallStartTipToClient: ", e);
        this.sysStatusChange(e);
      });
      socket.on("sysCallEndTipToClient", (e) => {
        console.log("sysCallEndTipToClient: ", e);
        this.sysStatusChange(e);
      });
      socket.on("newHighSeasNumTipTpClient", (e) => {
        if (e.userId != sessionStorage.getItem("userId") && this.saleType == 1) {
          this.getNewHighSeasNum();
        }
      });
    },
    /* 退出socket */
    handleLogoutSocket() {
      if (this.socket) {
        this.socket.disconnect();
        this.socket = null;
      }
    },
    /* 修改系统打电话信息 */
    handleSysCallStatusChange(data) {
      if (this.socket) {
        console.log("执行 sysCallStatusUpdateToServe");
        this.socket.emit("sysCallStatusUpdateToServe", data);
      }
    },
    /* 开启定时检测是否到达结束拨号时间 */
    handleStartCheckSysCallStatus() {
      this.handleStopCheckSysCallStatus();
      this._sysCallIntervalTimer = setInterval(() => {
        this.handleSysCallStatus();
      }, 1000);
    },
    /* 关闭定时检测是否到达结束拨号时间 */
    handleStopCheckSysCallStatus() {
      clearInterval(this._sysCallIntervalTimer);
    },
    /* 处理系统拨打时间改变及拨打开关的状态改变 */
    handleSysCallStatus() {
      const { startTime, endTime, status } = this.sysStatus;
      let flagOn = true,
        causeTxt = null;
      const curDate = dayjs().format("YYYY-MM-DD");
      if (status == 2) {
        flagOn = false;
        causeTxt = "系统电话功能已关闭！";
      } else if (startTime && endTime) {
        if (dayjs().isBefore(dayjs(`${curDate} ${startTime}`))) {
          flagOn = false;
          causeTxt = "还未到系统开始拨打时间！";
        } else if (dayjs().isAfter(dayjs(`${curDate} ${endTime}`))) {
          console.log(2222222);
          flagOn = false;
          causeTxt = "已到系统结束拨打时间！";
        }
      }
      this.sysCallInfo.flagOn = flagOn;
      this.sysCallInfo.causeTxt = causeTxt;
    },
    /* 系统不能打电话，尝试退出登录 */
    handleTryLogout() {
      if (this.sysCallInfo.flagOn) {
        return;
      }
      if (
        this.callInfo.callStatus == 1 ||
        this.callInfo.callStatus == 2 ||
        this.callInfo.callStatus == 3 ||
        this.callInfo.callStatus == 5 ||
        this.callInfo.callStatus == 6 ||
        this.callInfo.callStatus == 7
      ) {
        return;
      }
      if (
        this.callInfo.handCallStatus == 1 ||
        this.callInfo.handCallStatus == 2 ||
        this.callInfo.handCallStatus == 3 ||
        this.callInfo.handCallStatus == 5 ||
        this.callInfo.handCallStatus == 6 ||
        this.callInfo.handCallStatus == 7
      ) {
        return;
      }
      this.handleUserLogout();
    },
    /* 电话icon点击 */
    handleCallIconClick() {
      if (!this.dialogCallInfo.show) {
        this.dialogCallInfoChange({
          show: true,
          position: {
            left: "100px",
            top: "100px",
          },
        });
      }
    },
    /* 启动计时 */
    handleContinueTimeCompute() {
      clearInterval(this._continueTimeTimer);
      this._continueTimeTimer = setInterval(() => {
        if (this.callInfo.isCallLogin) {
          if (this.callInfo.isBusy) {
            this.callInfo._continueOffTime++;
            this.callInfo.continueOffTime = getTimeTotal(
              this.callInfo._continueOffTime,
              2
            );
          } else {
            this.callInfo._continueOnTime++;
            this.callInfo.continueOnTime = getTimeTotal(this.callInfo._continueOnTime, 2);
          }
        }
      }, 1000);
    },
    /* 初始化数据 */
    initData() {
      const authorities = sessionStorage.getItem("authorities");
      if (authorities) {
        this.updateAuthorities(JSON.parse(authorities));
      }
      if (this.saleType) {
        // saleType  1 销售专员 2 销售兼职
        // 刷新保持页面的电话的暂停或者运行状态
        window.addEventListener("beforeunload", (event) => {
          //event.returnValue = '你确定要离开吗？'
          sessionStorage.setItem("_lastHandBusyType", this.callInfo._lastHandBusyType);
        });
        if (
          this.callInfo.yyyAgentAccount &&
          sessionStorage.getItem("useCallPlatform") == 2
        ) {
          setTimeout(() => {
            this.handleUserLogin();
          }, 500);
        }
      }
    },
    /* 显示警告信息 */
    showConfirmInfo(msg) {
      this.$confirm(msg, "提示", {
        confirmButtonText: "我知道了",
        showCancelButton: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        type: "warning",
      })
        .then(() => {})
        .catch(() => {});
    },
    /* 电话框中位置提示 自定义消息提示 聊天消息提示 */
    handleMsgInfoCallBox(msg) {
      const dom = document.querySelector(".call-box-area");
      if (dom) {
        this.msgSuccess({
          message: msg,
          customClass: this.navIsOpen ? "msg-in-box-2" : "msg-in-box-1",
        });
      } else {
        this.msgSuccess(msg);
      }
    },
    /* 坐席登录 电话登录 */
    handleUserLogin() {
      // 获取最新刷新页面之前的状态，保证刷新后还是之前的开启暂停状态一致
      let _lastHandBusyType = sessionStorage.getItem("_lastHandBusyType");
      // 重置状态为默认值(默认进来是未开启状态)
      sessionStorage.setItem("_lastHandBusyType", defaultLastHandBusyType);
      let type = "ready";
      this.callInfo._lastHandBusyType = 0;
      if (_lastHandBusyType == 1 || _lastHandBusyType == null) {
        type = "busy";
        this.callInfo._lastHandBusyType = 1;
      }
      let obj = {
        key: "login", // 操作标识
        data: {
          id: this.callInfo.phoneId, // 对接ID
          agent: this.callInfo.yyyAgentAccount, // 登录账号，如'8004@1030test'
          password: this.callInfo.yyyAgentPassword, // 密码
          mode: "8", // 登录方式，1:手机登录 ，2:话机接听，4:插件登录， 6:WebRTC  7:SIM卡话机登录  8:SIP软电话登录  以api文档为准
          type, // 座席状态，ready:空闲，busy:忙碌，以api文档为准
        },
      };
      console.log("obj: ", obj);
      this.handleSaveCallData({
        eventType: "loginCall",
        ...obj
      })
      telephoneFunction(obj); // 调用对外函数
    },
    /* 坐席登出 */
    handleUserLogout() {
      if (!this.saleType) {
        return;
      }
      let obj = {
        key: "logout",
        data: {
          id: this.callInfo.phoneId, // 对接ID
        },
      };
      telephoneFunction(obj);
    },
    /* 分机外呼 | 手动拨打 */
    /* handCallType 1 手拨列表(首拨) 2 手拨列表(群呼) 3 我的意向列表 4 兼职意向 5 兼职预测外呼 6 专员接收的转接 7 销售专员我的客户资源 8 签约列表*/
    async handleHandCall(callednum, studentId, handCallType) {
      this.callInfo.isClickHandup = false;
      if (sessionStorage.getItem("useCallPlatform") == 0) {
        this.msgWarn("当前用户没有分配电话服务，不能拨打电话！");
        return;
      }
      if (!this.callInfo.isAllocCallAccount) {
        this.msgWarn("当前用户没有分配坐席，不能拨打电话！");
        return;
      }
      if(!this.callInfo.isCallLogin){
        this.msgWarn("坐席未登录！请稍等或者刷新重试！")
        return
      }
      // 用不同外显，拨打的是新数据，但是外显是真实数据外显
      const { isUseDiffExplicitNumber, explicitNumber1, explicitNumber2, explicitNumber0} = this.callInfo
      if(isUseDiffExplicitNumber && (handCallType == 1 || handCallType == 7) && explicitNumber0 == explicitNumber2){
        this.handleShowNumTip(1)
        return
      }
      // 用不同的外显，拨打的是真实数据，但是外显是新数据外显
      if(isUseDiffExplicitNumber && explicitNumber1 && explicitNumber2 && (handCallType == 2 || handCallType == 3 || handCallType == 4 || handCallType == 8) && explicitNumber0 != explicitNumber2){
        this.handleShowNumTip(2)
        return
      }
      if (this._callTimeout) {
        this.msgWarn("不要频繁点击，请稍后再点击！");
        return;
      }
      this._callTimeout = setTimeout(() => {
        this._callTimeout = null;
      }, 1000);
      if (this.dkyAgentType == 2) {
        this.msgWarn("坐席电话功能已被禁用！");
        return;
      }
      if (!this.sysCallInfo.flagOn) {
        this.msgWarn(this.sysCallInfo.causeTxt);
        return;
      }
      if (
        this.callInfo.callStatus == 1 ||
        this.callInfo.callStatus == 2 ||
        this.callInfo.callStatus == 3 ||
        this.callInfo.callStatus == 5 ||
        this.callInfo.callStatus == 6 ||
        this.callInfo.callStatus == 7
      ) {
        return this.msgWarn("正在通话中，请稍后再操作！");
      }
      if (
        this.callInfo.handCallStatus == 1 ||
        this.callInfo.handCallStatus == 2 ||
        this.callInfo.handCallStatus == 3 ||
        this.callInfo.handCallStatus == 5 ||
        this.callInfo.handCallStatus == 6 ||
        this.callInfo.handCallStatus == 7
      ) {
        return this.msgWarn("正在通话中，请稍后再操作！");
      }
      this.callInfo.isFromHandCall = true;
      this.callInfo.handCallType = handCallType;
      this.callInfo.handCallStatus = 1;
      this.callInfo.callType = 3;
      // 呼叫中计时
      this.handleStartTimeTimer();
      this.callInfo._isHandHangUp = false;
      this.callInfo._handCallStudentId = studentId;
      this.callInfo.isHandCallBlack = false;
      this.callInfo.changeCallStatus = 0;
      this.callInfo.blindferCallStatus = 0;
      let userfield = dealHandCallUserfield(studentId);
      /* if(envType == 'dev'){
        this.handleSaveCallRecord();
        this.callInfo.handCallStatus = 0
        return
      } */
      //外呼
      let obj = {
        key: "callOut",
        data: {
          id: this.callInfo.phoneId, // 对接ID
          telephone: String(callednum), // 被叫号码
          remark: { userfield }, // 扩展字段
        },
      };
      console.log("obj: ", obj);
      telephoneFunction(obj);
      this.handleSaveCallData({
        eventType: "HandCallClickEvent",
        ...obj,
      });
    },
    /* 暂停/恢复 */
    handlePauseOrRestore() {
      if (this.callInfo.callStatus != 3) {
        return;
      }
      if (this.callInfo.isPause) {
        let obj = {
          key: "resume",
          data: {
            id: this.callInfo.phoneId, // 对接ID
          },
        };
        telephoneFunction(obj);
        console.log("stopplay");
      } else {
        let obj = {
          key: "holdOn",
          data: {
            id: phoneId, // 对接ID
          },
        };
        telephoneFunction(obj);
        console.log("play");
      }
    },
    /* 开启计时 */
    handleStartTimeTimer() {
      this.handleStopTimeTimer();
      let s = 0;
      if (this.callInfo.callType == 1 || this.callInfo.callType == 2) {
        this.callInfo.duration = "00:00:00";
      } else if (this.callInfo.callType == 3) {
        this.callInfo.handDuration = "00:00:00";
      }
      this._timeTimer = setInterval(() => {
        s += 1000;
        let duration = dayjs.duration(s).format("HH:mm:ss");
        if (this.callInfo.callType == 1 || this.callInfo.callType == 2) {
          this.callInfo.duration = duration;
        } else if (this.callInfo.callType == 3) {
          this.callInfo.handDuration = duration;
        }
        console.log(" this.callInfo.duration: ", duration);
      }, 1000);
    },
    /* 关闭计时 */
    handleStopTimeTimer() {
      clearInterval(this._timeTimer);
    },
    /* 静音/取消静音 */
    handleMuteOrUnMute() {
      if (this.callInfo.callStatus != 3) {
        return;
      }
      if (this.callInfo.isMute) {
      } else {
      }
    },
    /* 置闲/置忙 (手动点击) */
    handleBusyOrFreeForHand(type, flag) {
      if (this.callInfo.loginStatus == 0) {
        this.msgWarn("请稍后再操作！");
        return;
      } else if (this.callInfo.loginStatus == 2) {
        this.msgWarn("电话登录失败！");
        return;
      } else if (this.callInfo.loginStatus == 3) {
        this.msgWarn("电话未登录！");
        return;
      }
      this.callInfo._lastHandBusyType = type;
      console.log("handleBusyOrFreeForHand type: ", type);
      // flag 是否是首次加载
      if (flag) {
        if (type == 0) {
          console.log("手动置闲");
          let obj = {
            key: "changeAgentStatus",
            data: {
              id: this.callInfo.phoneId, // 对接ID
              type: "ready", // ready’为切换至空闲，’busy’切换至忙碌
            },
          };
          telephoneFunction(obj);
        } else if (type == 1) {
          console.log("手动置忙");
          let obj = {
            key: "changeAgentStatus",
            data: {
              id: this.callInfo.phoneId, // 对接ID
              type: "busy", // ready’为切换至空闲，’busy’切换至忙碌
            },
          };
          telephoneFunction(obj);
        }
      } else {
        if (type == 0) {
          if (this.callInfo.isBusy && type == this.callInfo._lastAutoBusyType) {
            console.log("手动置闲");
            let obj = {
              key: "changeAgentStatus",
              data: {
                id: this.callInfo.phoneId, // 对接ID
                type: "ready", // ready’为切换至空闲，’busy’切换至忙碌
              },
            };
            telephoneFunction(obj);
          }
        } else if (type == 1) {
          if (!this.callInfo.isBusy) {
            console.log("手动置忙");
            let obj = {
              key: "changeAgentStatus",
              data: {
                id: this.callInfo.phoneId, // 对接ID
                type: "busy", // ready’为切换至空闲，’busy’切换至忙碌
              },
            };
            telephoneFunction(obj);
          }
        }
      }
    },
    /* 置闲/置忙（根据接听电话信息程序自动判断） */
    handleBusyOrFreeForAuto(type) {
      this.callInfo._lastAutoBusyType = type;
      if (type == 0) {
        if (this.callInfo.isBusy && type == this.callInfo._lastHandBusyType) {
          console.log("客户信息自动置闲");
          let obj = {
            key: "changeAgentStatus",
            data: {
              id: this.callInfo.phoneId, // 对接ID
              type: "ready", // ready’为切换至空闲，’busy’切换至忙碌
            },
          };
          telephoneFunction(obj);
        }
      } else if (type == 1) {
        if (!this.callInfo.isBusy) {
          console.log("客户信息自动置忙");
          let obj = {
            key: "changeAgentStatus",
            data: {
              id: this.callInfo.phoneId, // 对接ID
              type: "busy", // ready’为切换至空闲，’busy’切换至忙碌
            },
          };
          telephoneFunction(obj);
        }
      }
    },
    /* 挂断 */
    handleDKYHangup() {
      if (this._hangupTimeout) {
        this.msgWarn("请稍等再操作！");
        return;
      }
      this._hangupTimeout = setTimeout(() => {
        this._hangupTimeout = null;
      }, 1000);
      if (this.callInfo.callType == 3) {
        this.callInfo._isHandHangUp = true;
      }
      console.log("执行了 挂断操作");
      try {
        let obj = {
          key: "hangup",
          data: {
            id: this.callInfo.phoneId, // 对接ID
          },
        };
        telephoneFunction(obj);
        this.handleSaveCallData({
          eventType: "HandUpClickEvent",
        });
      } catch (err) {
        this.handleSaveCallData({
          eventType: "HandUpClickErrEvent err",
        });
        console.log("HandUpClickErrEvent : ", err);
      }
    },
    /* 转接情况未接听挂断 */
    handleDKYHangupAll() {
      this.callInfo.isClickHandup = true;
      if (this._hangupTimeout) {
        this.msgWarn("请稍等再操作！");
        return;
      }
      this._hangupTimeout = setTimeout(() => {
        this._hangupTimeout = null;
      }, 1000);
      if (this.callInfo.callType == 3) {
        this.callInfo._isHandHangUp = true;
      }
      console.log("转接挂断, this.callInfo.linkedid: ", this.callInfo.linkedid);
      this.handleSaveCallData({
        eventType: "HandUpClickEvent",
      });
      let obj = {
        key: "hangup",
        data: {
          id: this.callInfo.phoneId, // 对接ID
        },
      };
      telephoneFunction(obj);
    },
    /* 接听 */
    handleDKYAnswer() {
      this.callInfo.callStatus = 6;
      this.handleSaveCallData({
        eventType: "AnswerClickEvent",
      });
      let obj = {
        key: "answer",
        data: {
          id: this.callInfo.phoneId, // 对接ID
        },
      };
      telephoneFunction(obj);
    },
    /* 盲转 */
    handleDKYBindfer(ext2) {
      this.callInfo.isClickHandup = false;
      if (this._changeOverFlag) {
        this.msgWarn("请稍等再操作！");
        return;
      }
      this._changeOverFlag = true;
      setTimeout(() => {
        this._changeOverFlag = false;
      }, 1000);
      this._bindferComputeNum = 0;
      clearInterval(this._bindferEndTimer);
      this._bindferEndTimer = setInterval(() => {
        // 盲转超过60秒没有接听，自动挂断
        if (this._bindferComputeNum > 60 && this.callInfo.blindferCallStatus == 1) {
          clearInterval(this._bindferEndTimer);
          this._bindferEndTimer = null;
          this.callInfo.blindferCallStatus = 0;
          this.msgWarn("转接方未接听，通话结束！");
          this.handleDKYHangupAll();
        }
        this._bindferComputeNum++;
      }, 1000);
      let obj = {
        key: "transfer",
        data: {
          id: this.callInfo.phoneId, // 对接ID
          type: "agent", // agent：转接到座席，phone：转接到号码
          value: ext2, // 座席工号/被叫号码
        },
      };
      telephoneFunction(obj);
      this.callInfo.blindferCallStatus = 1;
      console.log("转接");
    },
    /* 转接 */
    handleDKYChangeOver(ext2) {
      this.callInfo.isClickHandup = false;
      this.handleDKYBindfer(ext2);
    },
    /* 转接挂断 */
    handleDKYChangeOverHangUp() {
      this.callInfo.isClickHandup = true;
      let obj = {
        key: "hangup",
        data: {
          id: this.callInfo.phoneId, // 对接ID
        },
      };
      telephoneFunction(obj);
      console.log("取消转接 this.callInfo.linkedid： ", this.callInfo.linkedid);
    },
    /* 转接咨询 */
    handleConsultTransfer() {
      let obj = {
        key: "consultTransfer",
        data: {
          id: this.callInfo.phoneId, // 对接ID
        },
      };
      telephoneFunction(obj);
    },
    /* 取消咨询 */
    handleCancelConsult() {
      let obj = {
        key: "cancelConsult",
        data: {
          id: this.callInfo.phoneId, // 对接ID
        },
      };
      telephoneFunction(obj);
    },
    /* 三方通话 */
    handleThreeWay() {
      let obj = {
        key: "threeWay",
        data: {
          id: this.callInfo.phoneId, // 对接ID
        },
      };
      telephoneFunction(obj);
    },
    /* 兼职加入有用 */
    handleParttimeAddUse(data) {
      if (this.socket) {
        this.socket.emit("parttimeAddUse", data);
      }
    },
    /* 释放数据到公海 */
    handleReleaseToHighSeas() {
      if (this.socket) {
        this.socket.emit("highSeasNumAdd", { userId: sessionStorage.getItem("userId") });
      }
    },
    /* 销售专员查看兼职加入的有用 */
    handleSaleStaffSeeUse() {
      if (this.newAddUseNum == 0) {
        return;
      }
      if (this.socket) {
        this.newAddUseNum = 0;
        this.socket.emit("saleSeeUse");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  position: relative;
  .call-short {
    position: fixed;
    left: 20px;
    bottom: 20px;
    z-index: 99999;
    .call-img {
      cursor: pointer;
      width: 30px;
      transition: transform 1s;
      &:hover {
        transform: scale(1.4);
      }
    }
  }
  .left {
    position: relative;
    z-index: 20;
  }
  .right {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    .main {
      flex: 1;
      overflow-y: hidden;
      overflow-x: hidden;
      min-width: 1160px;
      .page-area {
        height: 100%;
        overflow-x: auto;
        position: relative;
      }
    }
  }
}
</style>
