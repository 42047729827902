<template>
  <div class="file-view" v-show="showPreview">
     <div class="file-container">
      <iframe
        :src="url"
        frameborder="0"
        class="file-iframe"
      ></iframe>
    </div>
    <div class="close-btn" title="关闭" @click="handleClose">
      <span class="dialog-close iconfont icon-sys-shibai" ></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "HzxmanageFilePreview",

  data() {
    return {
      showPreview: false,
      url: "",
    };
  },

  mounted() {},

  methods: {
    init() {
      this.showPreview = true;
    },
    handleClose() {
      setTimeout(() => {
        this.$destroy(true);
        this.$el && this.$el.parentNode.removeChild(this.$el);
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.file-view {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba($color: #000000, $alpha: 0.7);
  z-index: 99999999;
  display: flex;
  justify-content: center;
  align-items: center;
  .file-container {
    width: 900px;
    height: 100vh;
    background-color: #ffffff;
    .file-iframe {
      width: 100%;
      height: 100vh;
    }
  }
  .close-btn {
    position: absolute;
    right: 40px;
    top: 40px;
    cursor: pointer;
    .dialog-close{
        color: #ffffff;
        font-size: 40px;
    }
  }
}
</style>
