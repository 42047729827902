<template>
  <div class="_404">
    <div class="tip">抱歉，您访问的页面不存在！</div>
    <div class="opt-area">
      <div class="go-login" @click="handleRefresh">请刷新重试</div>
      <div class="info-txt">或者</div>
      <div class="go-login" @click="handleLogin">去登陆</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  components: {},
  computed: {},
  mounted() {},
  methods: {
    /* 去登陆 */
    handleLogin() {
      this.$router.push({
        path: "/",
      });
    },
    /* 刷新 */
    handleRefresh() {
      location.reload()
    },
  },
};
</script>
<style lang="scss" scoped>
._404 {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .tip {
    font-size: 20px;
  }
  .opt-area {
    margin-top: 20px;
    display: flex;
    align-items: center;
    .info-txt{
      font-size: 16px;
      margin: 0 20px;
    }
    .go-login {
      color: blueviolet;
      cursor: pointer;
      font-size: 40px;
    }
  }
}
</style>
