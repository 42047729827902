let parttimeUserListRefreshKey = 0
let myParttimeIntentionListRefreshKey = 0;
export const callInfo = {
    namespaced: true,
    state: () => ({
        sysStatus: {
            status: 1, // 1 开启 2 关闭
            startTime: null,
            endTime: null,
            dataFlag: false,  // 数据已获取
        },
        parttimeUserListRefreshKey,
        myParttimeIntentionListRefreshKey,
    }),
    getters: {

    },
    mutations: {
        parttimeUserListRefreshKeyChange(state) {
            state.parttimeUserListRefreshKey = ++parttimeUserListRefreshKey
        },
        myParttimeIntentionListRefreshKeyChange(state) {
            state.myParttimeIntentionListRefreshKey = ++parttimeUserListRefreshKey
        },
        sysStatusChange(state, val) {
            for(const key in val) {
                if(state.sysStatus[key] != val[key]){
                    state.sysStatus[key] = val[key]
                }
            }
            // state.sysStatus = { ...state.sysStatus, ...val}
        },
    },
    actions: {
        
    },
}