const setIndex = (state, obj) => {
    if (state.maxIndex != obj.zIndex) {
        state.maxIndex = obj.zIndex = state.maxIndex + 1
    }
}
export const dialogCont = {
    namespaced: true,
    state: () => (
        {
            maxIndex: 3001,
            dialogCallInfo: {
                zIndex: 3000,
                show: false,
                position: {
                    "left": '100px',
                    "top": '100px',
                    "right": 'auto',
                    "bottom": 'auto',
                }
            },
        }
    ),
    getters: {

    },
    mutations: {
        dialogCallInfoChange(state, val) {
            state.dialogCallInfo = { ...state.dialogCallInfo, ...val }
            setIndex(state, state.dialogCallInfo)
        },
    },
    actions: {

    },
}