import Vue from 'vue'
import ImagePreview from './imagePreview.vue'

const MyImagePreview = Vue.extend(ImagePreview)

ImagePreview.install = (Vue) => {
  return Vue.prototype.$ImagePreview = (options) => {
    /* url 或者 imgList (url | index) 或者 url index imgList */
    let { url, imgList, index} = options
    if(!url && index != undefined && imgList){
      url = imgList[index]
    }
    if(imgList == undefined && url){
        imgList = [url]
    }
    if(index == undefined & url){
        index = imgList.indexOf(url) 
        if(index == -1){
            index = 0
        }
    }
    let instance = new MyImagePreview({
      data: {
        index,
        url,
        imgList,
      },
    }).$mount()
  
    document.body.appendChild(instance.$el)

    return instance.init()
  }
}

export default ImagePreview