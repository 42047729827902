<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      title="视频预览"
      :visible.sync="showPreview"
      width="600px"
      center
      @close="handleClose"
    >
      <div class="video-content">
        <video class="video-dom" :src="url" width="" height="" controls></video>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "HzxmanageVideoPreview",

  data() {
    return {
      showPreview: false,
      url: "",
    };
  },

  mounted() {},

  methods: {
    init() {
      this.showPreview = true;
    },
    handleClose() {
      setTimeout(() => {
        this.$destroy(true);
        this.$el && this.$el.parentNode.removeChild(this.$el);
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.video-content {
  display: flex;
  justify-content: center;
  align-items: center;
  .video-dom {
    max-width: 100%;
    max-height: 60vh;
  }
}
</style>
